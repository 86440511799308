import { useEffect } from "react";

const App = () => {
  useEffect(() => {
    if (process.browser) {
      window.location.replace(
        !!navigator.platform
                && /iPad|iPhone|Mac|iPod/.test(navigator.platform)
          ? "https://gorather.com/ios"
          : "https://gorather.com/android",
      );
    }
  }, []);

  return null;
};

export default App;
